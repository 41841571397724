@font-face {
  font-family: "RobotoCondensed";
  src: url("../src/Assets/Fonts/Roboto_Condensed/static/RobotoCondensed-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "RobotoCondensedRegular";
  src: url("../src/Assets/Fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Swanky";
  src: url("../src/Assets/Fonts/Swanky/swankyandmoomoocyrillic.woff");
  font-weight: normal;
  font-style: normal;
}

@keyframes animBurger {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  background: #f2f5f7;
}
body::-webkit-scrollbar-thumb {
  background-color: #0f8a40;
}

input::placeholder {
  font-family: "RobotoCondensed";
}

select,
input[type="date"],
input[type="file"] {
  font-family: "RobotoCondensed";
}

@media (max-width: 1200px) {
  .App {
    padding-top: 0 !important;
  }

  .applicationInput div,
  input,
  select,
  .designedPassword {
    width: 350px !important ;
    font-size: 1rem !important;
  }

  .applicationHead {
    width: 844px !important;
  }

  .designedTable {
    width: 95% !important;
  }

  .customCheckbox {
    width: 20px !important;
  }

  .teamName {
    font-size: 1.8rem !important;
  }

  .teamIcon img {
    height: 250px !important;
  }
}

@media (max-width: 900px) {
  .applicationInput div,
  input,
  select,
  .designedPassword {
    width: 90% !important ;
    font-size: 0.8rem !important;
  }

  .tableHead div,
  .tableRow div,
  .tableRow a {
    font-size: 0.8rem !important;
  }

  .teamIcon img {
    height: 250px !important;
  }

  .applicationHead {
    width: 493px !important;
    background-size: 40px;
  }

  .teamHead {
    background-position: 15px 65px !important;
    background-size: 40px;
  }

  .videoPopUp {
    width: 90% !important;
  }

  .videoPopUp {
    height: 450px !important;
  }

  .footerLogoImage {
    height: 100px !important;
  }

  .footerRGUImage {
    height: 100px !important;
  }
}

@media (max-width: 600px) {
  .registrationForm {
    width: 85% !important;
  }

  .applicationListSetup {
    align-items: center !important;
    flex-direction: column !important;
  }

  .addParticipantButton,
  .soloButton {
    border-top-right-radius: 30px !important;
    border-bottom-left-radius: 0 !important;
  }

  .deleteParticipantButton,
  .teamButton {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 30px !important;
  }
  .selectTeamType {
    flex-direction: column !important;
  }

  .tableBody {
    padding: 0 !important;
  }

  .tableHead {
    gap: 5px !important;
    padding: 0 !important;
  }

  .teamList {
    padding: 5px !important;
  }

  .teamParticipantPhoto {
    height: 85px !important;
  }

  .downloadCertificat div {
    width: 80% !important;
  }

  .teamParticipantName {
    font-size: 1rem !important;
  }

  .teamCard {
    flex-direction: column !important;
    padding-bottom: 20px;
  }

  .teamIcon {
    margin-bottom: 10px;
  }

  .teamIcon img {
    height: 47.1vw !important;
  }

  .teamHead {
    font-size: 30px !important;
    height: 120px !important;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .teamHead img {
    height: 100px !important;
  }

  .applicationHead {
    width: 90% !important;
    font-size: 45px !important;
  }

  .applicationHead img {
    height: 100px !important;
  }

  .designedButton {
    width: 85% !important;
  }

  .footerLogoImage {
    height: 85px !important;
  }

  .footerRGUImage {
    height: 85px !important;
  }
}

@media (max-height: 600px) {
  .videoPopUp {
    height: 80% !important;
  }
}

@media (max-height: 450px) {
}

body {
  font-family: "RobotoCondensed";
  margin: 0;
}

.App {
  padding-top: 20px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  top: 15px;
  z-index: 2;
}

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 1200px;
  height: 80px;
  border-radius: 50px;
  background-color: #0a0f47;
  z-index: 5;
}

.headerContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 40px;
  margin-right: 40px;
}

.burgerContent {
  margin: 40px;
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.closeBurgerBox {
  display: flex;
  justify-content: flex-end;
  padding-right: 15px;
  margin-top: 15px;
}

.closeBurgerButton {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 22px;
  height: 22px;
}

.closeBurgerButton span {
  position: absolute;
  background-color: #ffffff;
  width: 22px;
  height: 2px;
  transform: rotate(-45deg);
}

.closeBurgerButton span:nth-child(1) {
  transform: rotate(-45deg);
}
.closeBurgerButton span:nth-child(2) {
  transform: rotate(45deg);
}

.headerContent a,
.headerContent div,
.headerContent img,
.burgerContent a,
.burgerContent div,
.burgerContent img {
  cursor: pointer;
}

.headerContent a,
.burgerContent a,
.headerContent div,
.burgerContent div {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
}

.headerContent a,
.headerContent div,
.adminButtonBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  margin-top: 150px;
  height: 360px;
  width: 100%;
  background-color: #0a0f47;
  display: flex;
  align-items: center;
}

.footerContent {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footerBlock {
  color: #f2f5f7;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.headLogoImage {
  height: 65px;
}

.footerLogoImage {
  height: 200px;
}

.footerRGUImage {
  height: 150px;
}

.burgerLogo {
  height: 120px;
}

.burgerButtonBox {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 15px;
  right: 15px;
  background-color: #0a0f47;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  z-index: 2;
}

.burgerButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 22px;
  height: 14px;
}

.burgerButton span {
  background-color: #ffffff;
  width: 22px;
  height: 2px;
}

.burgerContainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.burgerBox {
  position: absolute;
  background-color: #0a0f47;
  max-width: 360px;
  width: 100%;
  height: 100%;
  right: 0;
  animation: animBurger 0.5s;
}

.registrationForm {
  background-image: url("../src/Assets/Images/orange_empty_puzzle_rotate.png");
  background-repeat: no-repeat;
  background-position: -15px -5px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* border: solid 5px #0a0f47; */
  background-color: #0a0f47;
  margin-top: 20px;
  border-radius: 30px;
  padding: 20px;
  align-items: center;
  width: 600px;
  gap: 20px;
}

.switchSign {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #f2f5f7;
}

.goEnter {
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: white;
}

.designedInput {
  width: 400px;
  height: 30px;
  padding: 10px;
  border-radius: 30px;
  border: solid 5px #14c059;
  background-color: #f2f5f7;
  font-size: 20px;
}

.designedInput:focus,
.designedPassword:focus-within {
  border: solid 5px #0f8a40;
  outline: none;
  transition: 0.25s;
  transform: scale(1.02);
}

.designedInput::placeholder,
.passwordInput::placeholder {
  font-size: 20px;
  text-align: center;
}

.designedPassword {
  width: 400px;
  height: 30px;
  padding: 10px;
  border-radius: 30px;
  border: solid 5px #14c059;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  background-color: #f2f5f7;
}

.passwordInput {
  margin: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background-color: #f2f5f7;
}

.passwordInput::placeholder {
  margin-left: -50px;
}

.designedPassword span {
  width: 50px !important;
  margin: 0 !important;
  margin-left: 5px !important;
  padding: 0 !important;
}

.eye {
  cursor: pointer;
}

.designedButton {
  background-color: #14c059;
  border: none;
  color: white;
  width: 350px;
  height: 50px;
  border-radius: 30px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.25s;
}

.designedButton:hover {
  background-color: #0a0f47;
  border: solid 3px #14c059;
  /* transform: scale(1.05); */
}

.orangeButton {
  background-color: #ff8100;
}

.orangeButton:hover {
  background-color: #0a0f47;
  border: solid 3px #ff8100;
}

.applicationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.application {
  margin-bottom: 20px;
  /* border: solid 5px #0a0f47; */
  background-color: #0a0f47;
  color: #f2f5f7;
  border-radius: 30px;
  font-size: 24px;
}

.applicationHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background-color: #ff8100;
  color: #f2f5f7;
  height: 150px;
  width: 1150px;
  border-radius: 30px;
  margin-bottom: 50px;
  text-align: center;
  z-index: 1 !important;
  font-family: "Swanky";
  font-size: 65px;
  padding-bottom: 30px;
}

.applicationHead img {
  height: 150px;
}

/* .topApplication {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  margin-top: -30px;
  z-index: 2 !important;
  background-color: #0a0f47;
} */

.selectTeamType {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.selectTeamType div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 3px #14c059;
}

.applicationInput {
  display: flex;
  flex-direction: row;
  border-top: solid 1px #0a0f47;
  align-items: center;
}

.applicationInput div,
input,
select {
  width: 500px;
  font-size: 24px;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
}

.applicationInput input {
  border: none;
  background-color: #f2f5f7;
}

.applicationInput input[type="checkbox"] {
  height: 25px;
}

.topApplicationInput input {
  background-color: #0a0f47;
  color: #f2f5f7 !important;
}

.topApplicationInput input::placeholder {
  color: #f2f5f7cb;
}

.errorMed input,
.error select,
.error input[type="date"],
.error input::placeholder {
  color: red;
}

.error input[type="checkbox"]::after {
  content: "*";
  color: red;
}

.applicationListSetup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.personalBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.personalBox input {
  width: 10% !important;
}

.personalBox a {
  color: #f2f5f7;
  text-underline-offset: 6px;
}

.addParticipantButton,
.deleteParticipantButton {
  width: 220px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.25s;
}

.addParticipantButton {
  background-color: #14c059;
  border: none;
  color: white;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.addParticipantButton:hover {
  background-color: #0a0f47;
  border: solid 3px #14c059;
}

.deleteParticipantButton {
  background-color: #0a0f47;
  border: solid 3px #14c059;
  color: #f2f5f7;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.deleteParticipantButton:hover {
  color: white;
  border: solid 3px #14c059;
  background-color: #14c059;
}

.soloButton,
.teamButton {
  width: 220px;
  height: 50px;
  font-size: 20px;
  cursor: pointer;
  transition: 0.25s;
}

.soloButton:hover,
.teamButton:hover {
  color: white;
  border: solid 3px #14c059;
  background-color: #14c059;
}

.soloButton {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.teamButton {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.selectedTeamType {
  background-color: #14c059;
  border: none;
  color: white;
}

.memo {
  font-size: 16px;
  margin: 20px;
}

.participantList {
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 930px;
}

.participant {
  border: solid 5px #14c059;
  background-color: #f2f5f7;
  color: #0a0f47;
  border-radius: 30px;
  margin-top: 10px;
  margin: 10px !important;
}

.participantHead {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}

.designedTable {
  margin: 50px auto;
  border: solid 5px #0a0f47;
  border-radius: 30px;
  width: 1000px;
  height: 500px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.adminTeamTable {
  height: auto !important;
}

.tableHead {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.tableHead div {
  width: 300px;
  font-size: 20px;
  text-align: center;
  word-break: break-all;
}

.tableBody {
  border-top: solid 1px #0a0f47;
  padding: 10px;
  gap: 20px;
  overflow-y: auto;
}

.tableRow div,
.tableRow a {
  width: 300px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.tableBody::-webkit-scrollbar {
  width: 8px;
}
.tableBody::-webkit-scrollbar-track {
  background: #f2f5f7;
}
.tableBody::-webkit-scrollbar-thumb {
  background-color: #0a0f47;
  /* background-color: #EC7D29; */
}

.tableRow {
  border-bottom: solid 1px #0a0f4750;
  margin-top: 20px;
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 20px;
  font-family: "RobotoCondensedRegular";
  cursor: pointer;
}

.tableRowDisabled {
  cursor: default;
  color: rgba(0, 0, 0, 0.438);
}

.tableRowInput {
  width: 100%;
  border: solid 1px #0a0f4750;
  margin-bottom: 10px;
  border-radius: 10px;
}

.adminButtonBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.backToScore {
  display: flex;
  justify-content: center;
}

.boldRow {
  font-family: "RobotoCondensed";
}

.tableTeamName:hover {
  color: #0f8a40;
  cursor: pointer;
  transition: 0.25s;
}

.customCheckboxInput[type="checkbox"] {
  display: none;
}

.customCheckbox {
  width: 20px !important;
  height: 20px;
  background-color: #14c059;
  display: inline-block;
  position: relative;
}

.customCheckbox::after {
  content: "";
  position: absolute;
  left: 5px;
  width: 8px;
  height: 14px;
  bottom: 5px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  display: none;
}

.customCheckboxInput[type="checkbox"]:checked + .customCheckbox::after {
  display: block;
}

.teamHead {
  display: flex;
  margin: 50px auto;
  height: 200px;
  font-family: "Swanky";
  font-size: 65px;
  line-height: 50px;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  background-color: #ff8100;
  color: #f2f5f7;
  border-radius: 30px;
  width: 90%;
  overflow: hidden;
}

.teamCard {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  background-color: #0a0f47;
  border-radius: 30px;
  color: #f2f5f7;
  width: 90%;
}

.teamCardContent {
  width: 100%;
}

.teamName {
  margin-top: 10px;
  font-size: 50px;
  text-align: center;
}

.teamIcon {
  display: flex;
  justify-content: center;
}

.teamIcon img {
  height: 350px;
  margin: 0 auto;
  border-radius: 25px;
}

.teamURL {
  margin: 20px;
  display: flex;
  justify-content: center;
  font-size: 24px !important;
}

.teamURL div {
  color: #f2f5f7;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 4px;
  cursor: pointer;
}

.downloadApprove {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.downloadCertificat div {
  margin: 50px auto;
  border-radius: 30px;
  background-color: #14c059;
  font-size: 24px;
  width: 90%;
  padding: 10px;
  color: #f2f5f7;
  text-align: center;
}

.downloadPosition div,
.downloadApprove div {
  border-radius: 30px;
  font-size: 24px;
  border: solid 3px #0a0f47;
  width: 90%;
  padding: 10px;
  color: #f2f5f7;
  text-align: center;
  transition: 0.25s;
  color: #0a0f47;
  cursor: pointer;
}

.downloadPosition div {
  margin: 50px auto;
}

.downloadApprove div {
  margin: 20px auto;
}

.downloadApprove div,
.downloadApprove input,
.downloadApprove label {
  width: 90%;
}

.downloadApprove label {
  font-size: 1.6rem;
  text-align: center;
  color: #ff8100;
  margin-bottom: 10px;
}

.teamList {
  margin-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.teamParticipantPhoto {
  height: 150px;
  border-radius: 30px;
}

.emptyTeamPhoto {
  background-color: #f2f5f7;
  height: 250px !important;
}

.emptyMemberPhoto {
  background-color: #f2f5f7;
}

.teamParticipant {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  background-color: #0a0f47;
  color: #f2f5f7;
  border-radius: 50px;
  cursor: pointer;
}

.teamParticipantName {
  margin-top: 10px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  font-size: 24px;
  gap: 5px;
  text-align: center;
}

.popUp {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.37);
}

.videoInfo {
  margin-left: 20px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #ff8100;
}

.videoPopUp {
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  padding: 10px;
  width: 600px;
  background-color: white;
  border: solid 3px #14c059;
  border-radius: 30px;
  z-index: 2;
  overflow-y: auto;
}

.memberPopUpContent {
  display: flex;
  flex-direction: column;
}

.memberPopUpContent img {
  height: 150px;
  width: auto;
}

.closePopUp {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.memberImageBox {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.memberImageBox img {
  border-radius: 30px;
}

.memberPopBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  gap: 10px;
}
